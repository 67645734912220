/* General container styling */
.video-container {
    padding: 20px;
    color: #ddd;
    background: linear-gradient(to bottom right, #1e1e1e, #282828);
    min-height: 100vh;
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Header */
  .video-container h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #e50914;
    text-align: center;
  }
  
  /* Video Grid */
  .video-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsive grid with smaller cards */
    gap: 15px;
    width: 100%;
    max-width: 1400px;
    margin-bottom: 20px;
  }
  
  /* Video Card */
  .video-card {
    background: #333;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s;
  }
  
  .video-card:hover {
    transform: scale(1.05);
  }
  
  .video-thumbnail {
    width: 100%;
    height: 120px; /* Fixed height to avoid overflow */
    object-fit: cover;
    border-radius: 8px;
  }
  
  .video-info {
    margin-top: 10px;
  }
  
  .video-title {
    font-size: 16px;
    font-weight: bold;
    color: #e50914;
  }
  
  .video-description {
    margin-top: 5px;
    font-size: 12px;
    color: #ccc;
  }
  
  .video-date {
    margin-top: 5px;
    font-size: 12px;
    color: #bbb;
  }
  
  /* Pagination Controls */
  .pagination-controls {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    margin-top: 20px;
  }
  
  /* Pagination Button */
  .pagination-button {
    padding: 10px 30px;
    background-color: #e50914;
    color: white;
    border: none;
    border-radius: 25px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.4);
  }
  
  /* Button Hover Effect */
  .pagination-button:hover {
    background-color: #ff3333;
    transform: translateY(-3px);
  }
  
  /* Disable Button */
  .pagination-button:disabled {
    background-color: #555;
    cursor: not-allowed;
  }
  
  /* Center buttons on smaller screens */
  @media (max-width: 600px) {
    .pagination-controls {
      flex-direction: column;
      align-items: center;
    }
    
    .pagination-button {
      margin-bottom: 10px;
      width: 100%;
    }
  }
  
  /* Error Message */
  .error {
    color: #ff3333;
    font-size: 18px;
    text-align: center;
  }
  