/* Overall Page Styles */
.playlist-videos-page {
    padding: 20px;
    max-width: 100%;
    margin: 0 auto;
    background: linear-gradient(to bottom right, #1e1e1e, #282828);
    color: #fff;
    font-family: 'Roboto', sans-serif;
    min-height: 100vh; /* Ensures page covers full viewport height */
  }
  
  /* Playlist Header */
  .playlist-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 15px 20px;
    background-color: #1e1e1e;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    border-bottom: 2px solid #e50914; /* Adds a striking accent color */
  }
  
  .controls {
    display: flex;
    gap: 20px;
  }
  
  .search-bar {
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    font-size: 16px;
    outline: none;
    transition: width 0.3s ease-in-out;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    background-color: #333;
    color: white;
  }
  
  .search-bar::placeholder {
    color: #999;
  }
  
  .search-bar:focus {
    width: 350px; /* Expands on focus for a modern UX */
  }
  
  @media screen and (max-width: 768px) {
    .search-bar {
      width: 100%;
    }
    .search-bar:focus {
      width: 100%;
    }
  }
  
  /* Back Button */
  .back-button {
    padding: 12px 25px;
    background-color: #e50914;
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
  }
  
  .back-button:hover {
    background-color: #ff3333;
  }
  
  /* Video Grid */
  .video-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px; /* Adds more breathing room between cards */
    padding: 20px;
  }
  
  @media screen and (max-width: 768px) {
    .video-grid {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  }
  
  /* Video Card */
  .video-card {
    background: #333;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .video-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
  }
  
  /* Adjust iframe/video thumbnail style */
  .video-card iframe {
    width: 100%;
    height: 180px;
    border-radius: 10px;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .video-card iframe:hover {
    transform: scale(1.02);
  }
  
  /* Video Title */
  .video-title {
    font-size: 18px;
    font-weight: bold;
    color: #e50914;
    margin: 12px 0;
    text-align: center;
    text-transform: capitalize;
    transition: color 0.3s ease;
  }
  
  .video-title:hover {
    color: #ff6666;
  }
  
  /* Video Details */
  .video-details {
    color: #fff;
  }
  
  .video-description {
    font-size: 14px;
    color: #ccc;
  }
  
  .video-date {
    font-size: 12px;
    color: #bbb;
  }
  
  /* Load More Button */
  .load-more-btn {
    margin-top: 30px;
    padding: 15px 40px;
    background-color: #e50914;
    color: white;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    transition: background-color 0.3s ease-in-out;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
  }
  
  .load-more-btn:hover {
    background-color: #f0131e;
  }
  
  .load-more-btn:disabled {
    background-color: #555;
    cursor: not-allowed;
  }
  
  /* Error Message */
  .error {
    color: #ff3333;
    font-size: 18px;
    text-align: center;
  }
  
  /* Additional Enhancements for Responsiveness */
  @media screen and (max-width: 768px) {
    .video-card iframe {
      height: 160px;
    }
  
    .video-title {
      font-size: 16px;
    }
  }
/* Overall Page Styles */
.playlist-videos-page {
  padding: 20px;
  max-width: 100%;
  margin: 0 auto;
  background: linear-gradient(to bottom right, #1e1e1e, #282828);
  color: #fff;
  font-family: 'Roboto', sans-serif;
  min-height: 100vh; /* Ensures page covers full viewport height */
}

/* Playlist Header */
.playlist-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 15px 20px;
  background-color: #1e1e1e;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border-bottom: 2px solid #e50914; /* Adds a striking accent color */
}

.controls {
  display: flex;
  gap: 20px;
}

.search-bar {
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  outline: none;
  transition: width 0.3s ease-in-out;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  background-color: #333;
  color: white;
}

.search-bar::placeholder {
  color: #999;
}

.search-bar:focus {
  width: 350px; /* Expands on focus for a modern UX */
}

@media screen and (max-width: 768px) {
  .search-bar {
    width: 100%;
  }
  .search-bar:focus {
    width: 100%;
  }
}

/* Back Button */
.back-button {
  padding: 12px 25px;
  background-color: #e50914;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #ff3333;
}

/* Video Grid */
.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px; /* Adds more breathing room between cards */
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .video-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

/* Video Card */
.video-card {
  background: #333;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.video-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
}

/* Adjust iframe/video thumbnail style */
.video-card iframe {
  width: 100%;
  height: 180px;
  border-radius: 10px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.video-card iframe:hover {
  transform: scale(1.02);
}

/* Video Title */
.video-title {
  font-size: 18px;
  font-weight: bold;
  color: #e50914;
  margin: 12px 0;
  text-align: center;
  text-transform: capitalize;
  transition: color 0.3s ease;
}

.video-title:hover {
  color: #ff6666;
}

/* Video Details */
.video-details {
  color: #fff;
}

.video-description {
  font-size: 14px;
  color: #ccc;
}

.video-date {
  font-size: 12px;
  color: #bbb;
}

/* Load More Button */
.load-more-btn {
  margin-top: 30px;
  padding: 15px 40px;
  background-color: #e50914;
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.3s ease-in-out;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
}

.load-more-btn:hover {
  background-color: #f0131e;
}

.load-more-btn:disabled {
  background-color: #555;
  cursor: not-allowed;
}

/* Error Message */
.error {
  color: #ff3333;
  font-size: 18px;
  text-align: center;
}

/* Additional Enhancements for Responsiveness */
@media screen and (max-width: 768px) {
  .video-card iframe {
    height: 160px;
  }

  .video-title {
    font-size: 16px;
  }
}
  