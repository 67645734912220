/* Overall Page Styles */
.videos-page {
    padding: 20px;
    max-width: 100%;
    margin: 0 auto;
    background: linear-gradient(to bottom right, #1e1e1e, #282828);
    color: #fff;
    font-family: 'Roboto', sans-serif;
    min-height: 100vh;
  }
  
  /* Header and Navbar */
  .videos-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px 20px;
    background-color: #1e1e1e;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    border-bottom: 2px solid #e50914;
  }
  
  .videos-header img {
    height: 50px;
  }
  
  nav {
    display: flex;
    gap: 20px;
  }
  
  nav a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    transition: color 0.3s ease-in-out;
  }
  
  nav a:hover {
    color: #e50914;
    text-shadow: 0 0 5px rgba(229, 9, 20, 0.7);
  }
  
  /* Search Bar */
  .search-bar {
    padding: 10px 20px;
    width: 300px;
    border: none;
    border-radius: 30px;
    font-size: 16px;
    background-color: #333;
    color: #fff;
    outline: none;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: width 0.3s ease-in-out;
  }
  
  .search-bar::placeholder {
    color: #999;
  }
  
  .search-bar:focus {
    width: 400px;
  }
  
  @media screen and (max-width: 768px) {
    .videos-header {
      flex-direction: column;
      gap: 15px;
    }
  
    nav {
      gap: 10px;
    }
  
    .search-bar {
      width: 100%;
    }
  
    .search-bar:focus {
      width: 100%;
    }
  }
  
  /* Featured Section */
  .featured-section {
    margin-bottom: 50px;
    text-align: center;
  }
  
  #featured-video {
    width: 100%;
    height: 500px;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease;
  }
  
  #featured-video:hover {
    transform: scale(1.02);
  }
  
  @media screen and (max-width: 768px) {
    #featured-video {
      height: 300px;
    }
  }
  
  /* Playlists Section */
  .playlists-section {
    margin-top: 40px;
    text-align: center;
  }
  
  .playlists-section h2 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #e50914;
  }
  
  .playlists-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 0 20px;
    justify-items: center; /* Center items within grid */
  }
  
  @media screen and (max-width: 768px) {
    .playlists-grid {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  }
  
  /* Playlist Cards */
  .playlist-card {
    background: #333;
    padding: 15px;
    border-radius: 12px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%; /* Ensure consistent width */
    max-width: 250px; /* Maximum width for cards */
  }
  
  .playlist-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
  }
  
  .playlist-card img {
    width: 100%;
    height: 160px;
    border-radius: 10px;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .playlist-card img:hover {
    transform: scale(1.02);
  }
  
  .playlist-title {
    font-size: 18px;
    font-weight: bold;
    color: #e50914;
    margin-top: 10px;
    text-align: center;
    text-transform: capitalize;
  }
  
  /* Reduced spacing between image and title */
  .playlist-card img {
    margin-bottom: 8px;
  }
  
  /* Responsive Title and Image Adjustments */
  @media screen and (max-width: 768px) {
    .playlist-card img {
      height: 140px;
    }
  
    .playlist-title {
      font-size: 16px;
      margin-top: 8px;
    }
  }
  
  /* Load More Button */
  .load-more-btn {
    margin-top: 30px;
    padding: 12px 30px;
    background-color: #e50914;
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: background-color 0.3s ease-in-out;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
  }
  
  .load-more-btn:hover {
    background-color: #f0131e;
  }
  
  .load-more-btn:disabled {
    background-color: #555;
    cursor: not-allowed;
  }
  
  /* Error Message */
  .error {
    color: #ff3333;
    font-size: 18px;
    text-align: center;
  }
  
  
  /* Existing styles... */
  
  /* Latest Videos Section */
  .latest-videos-section {
    margin: 40px 0;
  }
  
  .latest-videos-section h2 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    text-transform: uppercase;
    color: #e50914;
    text-align: center;
  }
  
  /* Container for horizontal scroll */
  .latest-videos-container {
    display: flex;
    overflow-x: auto;
    padding: 10px;
    gap: 20px;
    scrollbar-width: thin;
    scrollbar-color: #e50914 transparent;
  }
  
  /* For WebKit browsers */
  .latest-videos-container::-webkit-scrollbar {
    height: 8px;
  }
  
  .latest-videos-container::-webkit-scrollbar-thumb {
    background-color: #e50914;
    border-radius: 4px;
  }
  
  .latest-video-card {
    flex: 0 0 auto;
    width: 250px;
    background: #333;
    border-radius: 12px;
    padding: 15px;
    cursor: pointer;
    transition: transform 0.3s;
    text-align: center;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
  }
  
  .latest-video-card:hover {
    transform: scale(1.05);
  }
  
  .latest-video-card img {
    width: 100%;
    height: 140px;
    border-radius: 8px;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .video-title {
    font-size: 16px;
    color: #fff;
    font-weight: bold;
  }
  
  /* Adjustments for responsiveness */
  @media screen and (max-width: 768px) {
    .latest-video-card {
      width: 200px;
    }
  
    .latest-video-card img {
      height: 120px;
    }
  
    .video-title {
      font-size: 14px;
    }
  }
  