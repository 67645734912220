/* Fullscreen Layout */
.about-container {
    padding: 40px 20px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Aligns button at the bottom */
    align-items: center;
    background: linear-gradient(to bottom right, #1e1e1e, #282828);
    color: #ddd;
    font-family: 'Roboto', sans-serif;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  }
  
    /* Header Section */
    .about-header {
      text-align: center;
      margin-bottom: 20px;
    }
    
    .about-header h1 {
      font-size: 36px;
      color: #e50914;
      font-weight: bold;
    }
    
    /* Description Section */
    .about-description {
      font-size: 18px;
      line-height: 1.6;
      margin-bottom: 30px;
      color: #ccc;
      text-align: center;
      max-width: 900px; /* Limit the width of the text */
    }
    
    .about-description strong {
      color: #fff;
    }
    
    /* Links Section */
    .links-section {
      text-align: center;
      margin-top: 20px;
    }
    
    /* Social Media Links */
    .links-section h2 {
      font-size: 24px;
      color: #e50914;
      margin-bottom: 15px;
    }
    
   /* Social Media Icons */
  .social-links {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 15px;
  }
  
  .social-links a {
    color: white;
    font-size: 24px;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 50%;
    background-color: #333;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.4);
  }
  
  .social-links a:hover {
    background-color: #e50914;
    transform: translateY(-5px);
  }
  
    /* Go Back Button */
  .go-back-button {
    padding: 10px 20px;
    background-color: #e50914;
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 20px;
    transition: background-color 0.3s ease;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.4);
  }
  
  .go-back-button:hover {
    background-color: #ff3333;
  }